* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a {
    text-decoration: none;
}

ol,
ul {
    list-style-type: none;
}

button,
input,
select,
textarea,
fieldset {
    background-color: transparent;
    border: none;
    outline: none;
}

body {
	height: unset;
	min-height: unset;
	position: unset;
	top: unset;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--color-we);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-bk);
}

@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }





[data-p~="text.1"] {
	@include d("ft-fy=1-sibd");
	@include d("ft-se=70rem");
	@include d("le-ht=1.3");

	@include d("@2000=ft-se=50rem");
	@include d("@1600=ft-se=40rem");
	@include d("@1000=ft-se=24rem");
}

[data-p~="text.2"] {
	@include d("ft-fy=1-sibd");
	@include d("ft-se=50rem");
	@include d("le-ht=1.3");

	@include d("@2000=ft-se=40rem");
	@include d("@1600=ft-se=30rem");
	@include d("@1000=ft-se=20rem");
}

[data-p~="text.3"] {
	@include d("ft-fy=1-mm");
	@include d("ft-se=22rem");
	@include d("le-ht=1.4");

	@include d("@2000=ft-se=18rem");
	@include d("@1450=ft-se=16rem");
	@include d("@1000=ft-se=14rem");
}

[data-p~="text.4"] {
	@include d("ft-fy=1-bd");
	@include d("ft-se=160rem");
	@include d("le-ht=1.2");
	@include d("tt-tm=ue");
	@include d("we-se=nowp");

	@include d("@2000=ft-se=130rem");
	@include d("@1600=ft-se=100rem");
	@include d("@1450=ft-se=70rem");
	@include d("@1000=ft-se=30rem");
}

[data-p~="text.5"] {
	@include d("ft-fy=1-mm");
	@include d("ft-se=18rem");
	@include d("le-ht=1.2");

	@include d("@2000=ft-se=16rem");
}

[data-p~="text.6"] {
	@include d("ft-fy=1-sibd");
	@include d("ft-se=30rem");
	@include d("le-ht=1.3");

	@include d("@1000=ft-se=20rem");
}

[data-p~="text.7"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=14rem");
	@include d("le-ht=1.4");

	@include d("@1600=ft-se=13rem");
}

[data-p~="text.8"] {
	@include d("ft-fy=1-bd");
	@include d("ft-se=50rem");
	@include d("le-ht=1");

	// Anti cut bottom
	@include d("pg-bm=10px");

	@include d("@2000=ft-se=40rem");
	@include d("@1450=ft-se=30rem");
	@include d("@1000=ft-se=20rem");
}

// 01, 02, Counters
[data-p~="text.9"] {
	@include d("ft-fy=1-bd");
	@include d("ft-se=50rem");
	@include d("le-ht=1");

	// Anti cut bottom
	@include d("pg-bm=10px");

	@include d("@2000=ft-se=40rem");
	@include d("@1450=ft-se=30rem");
}

// Głównie kolorowe mini tytuły na podstronach
[data-p~="text.10"] {
	@include d("ft-fy=1-bd");
	@include d("ft-se=40rem");
	@include d("le-ht=1");

	// Anti cut bottom
	@include d("pg-bm=10px");

	@include d("@2000=ft-se=30rem");
	@include d("@1600=ft-se=25rem");
	@include d("@1000=ft-se=20rem");
}





[data-p~="content.1"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=18rem");
	@include d("le-ht=1.4");

	@include d("@2000=ft-se=16rem");

	a {
		@include d("co=main-2");

		&:hover {
			@include d("tt-dn=ue");
		}
	}
	h1, h2, h3, h4, h5, h6 {
		@include d("ft-se=18rem");
	}
	p:not(:first-child) {
		@include d("mn-tp=10px");
	}
	ul:not(:first-child) {
		@include d("mn-tp=10px");
	}
	ul {
		@include d("lt-se-te=dc");
		@include d("pg-lt=15px");
	}
	ol {
		@include d("lt-se-te=dc");
		@include d("pg-lt=15px");
	}
	img {
		@include d("mx-wh=100%");
	}
	iframe {
		@include d("mx-wh=100%");
	}
	table {
		@include d("mx-wh=100%");
	}
}





[data-p~="1"] {
	&:nth-child(1) {
		[data-p~="1.1"] {
			display: none;
		}
	}
	@media (max-width: 1000px) {
		&:nth-child(3),
		&:nth-child(4) {
			[data-p~="1.2"] {
				padding-top: 20px;
			}
		}
		&:nth-child(3) {
			[data-p~="1.1"] {
				display: none;
			}
		}
	}
}

// Multi card gradient
[data-p~="2"] {
	&:nth-child(1),
	&:nth-child(7) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-1) 0%, var(--color-gradient-2) 100%) }
	}
	&:nth-child(2),
	&:nth-child(8) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-3) 0%, var(--color-gradient-4) 100%) }
	}
	&:nth-child(3),
	&:nth-child(9) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-5) 0%, var(--color-gradient-6) 100%) }
	}
	&:nth-child(4),
	&:nth-child(10) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-7) 0%, var(--color-gradient-8) 100%) }
	}
	&:nth-child(5),
	&:nth-child(11) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-9) 0%, var(--color-gradient-10) 100%) }
	}
	&:nth-child(6),
	&:nth-child(12) {
		[data-p~="2.1"] { background: linear-gradient(90deg, var(--color-gradient-11) 0%, var(--color-gradient-12) 100%) }
	}
}

// Switcher
[data-p~="3"] {
	&:checked {
		~ [data-p~="3.1"] {
			background-color: var(--color-main-2);

			[data-p~="3.2"] {
				border-color: var(--color-we);
				transform: translateX(18px);
			}
		}
	}
	&:disabled {
		~ [data-p~="3.1"] {
			background-color: var(--color-main-2);
            opacity: .5;
		}
	}
}

[data-p~="4.1"] {
	&:focus,
	&:not(:placeholder-shown) {
		~ [data-p~="4.2"] {
			bottom: 100%;

			[data-p~="4.3"] {
				font-size: 11rem;
			}
		}
	}
}

// Counter
[data-p="5.1"] {
    height: 50px;

    @media screen and (max-width: 1000px) { height: 30px }

    > div {
        height: 50px;
        transition: transform 3s ease-in-out;

        @media screen and (max-width: 1000px) { height: 30px }

        > p {
            height: 50px;

            @media screen and (max-width: 1000px) { height: 30px }
        }
    }
}

[data-p="5.2"] {
    height: 180px;

	@media screen and (max-width: 2000px) { height: 140px }
	@media screen and (max-width: 1600px) { height: 120px }
    @media screen and (max-width: 1000px) { height: 60px }

    > div {
        height: 180px;
        transition: transform 3s ease-in-out;

		@media screen and (max-width: 2000px) { height: 140px }
		@media screen and (max-width: 1600px) { height: 120px }
		@media screen and (max-width: 1000px) { height: 60px }

        > p {
            height: 180px;

			@media screen and (max-width: 2000px) { height: 140px }
			@media screen and (max-width: 1600px) { height: 120px }
			@media screen and (max-width: 1000px) { height: 60px }
        }
    }
}

[data-p="6.1"] {
	&:hover {
		[data-p="6.2"] {
			@include d("an-ne=12");
			@include d("an-dn=1s");
			@include d("an-in-ct=ie");
			@include d("an-tg-fn=lr");
		}
	}
}

[data-p~="7"] {
	[data-p~="input"] {
		&:checked {
			+ [data-p~="button"] {

				[data-p~="background"] {
					@include g("36");
				}
				[data-p~="icon.1"] {
					@include a("ne");
				}
				[data-p~="icon.2"] {
					@include a("fx");
				}
				[data-p~="color"] {
					@include d("co=we");
				}
			}
		}
	}
}



















// Checkbox
[data-p~="1:1"] {
	&:hover,
	&:focus-within,
	&:checked {
		+ [data-p~="1:2"] {

			[data-p~="1:3"] {
				background-color: var(--color-bk);

				[data-p~="1:4"] {
					color: var(--color-we);
				}
			}
		}
	}
}

// Carousel
[data-p~="3:1"] {
    background-color: var(--color-8) !important;

    > span {
        background-color: var(--color-we) !important;
    }
}

[data-p~="3:2"] {
    background-color: var(--color-9) !important;

    > span {
        background-color: var(--color-bk) !important;
    }
}

[data-p~="4:1"] {
	&:focus,
	&:not(:placeholder-shown) {
		~ [data-p~="10:2"] {
			bottom: 100%;

			[data-p~="10:3"] {
				font-size: 11rem;
			}
		}
	}
}

[data-p~="5:1"] {
	appearance: none;
	cursor: pointer;

	&::-webkit-slider-runnable-track {
		background-color: var(--color-1);
		border-radius: 15px;
		height: 30px;
	}
	&::-moz-range-track {
		background-color: var(--color-1);
		border-radius: 15px;
		height: 30px;
	}
	&::-webkit-slider-thumb {
		appearance: none; 
		background-color: var(--color-theme-2);
		border-radius: 100%;
		width: 30px;
		height: 30px;
	}
	&::-moz-range-thumb {
		background-color: var(--color-theme-1);
		border-radius: 100%;
		width: 30px;
		height: 30px;
	}
}

@if $decoratorsEnabled {
    @each $decorator, $declaration in $decorators {
        @each $property, $value in $declaration {
            [#{$decoratorsAttribute}~="#{$decorator}"] {
                #{$property}: $value;
            }
        }
    }
    @if $decoratorsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media (max-width: $resolution) {
                @each $decorator, $declaration in $decorators {
                    @each $property, $value in $declaration {
                        [#{$decoratorsAttribute}~="@#{$breakpoint}=#{$decorator}"] {
                            #{$property}: $value;
                        }
                    }
                }
            }
        }
    }
}





@keyframes pulse_1__1 {
	100% {
		transform: scale(2);
        opacity: 0;
	}
}

@keyframes marquee_1__1 {
    100% {
        transform: translateX(-110%)
    }
}

@keyframes levitate_1__1 {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(-50px)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes levitate_2__1 {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(-30px)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes levitate_3__1 {
    0% {
        transform: translateY(0)
    }
    25% {
        transform: translateY(-5px)
    }
    50% {
        transform: translateY(0)
    }
    75% {
        transform: translateY(5px)
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes rotate_1__1 {
    100% {
        rotate: 360deg
    }
}

@keyframes triangles_1__1 {
	0% {
        clip-path: polygon(59.786% 53.1476%, 25.1063% 24.1675%, 75.4327% 51.6432%, 48.405% 4.74935%, 61.0629% 1.91479%, 67.1678% 28.9968%, 90.8913% -3.93364%, 37.738% 10.1199%, 83.8292% 14.627%, 22.1647% 78.1524%, 52.5993% 37.2246%, 85.7008% 88.9326%, 85.6679% 38.7789%, 8.79248% 1.92042%, 62.9664% 21.6092%, 46.965% 107.912%)
	}
    15% {
        clip-path: polygon(23.1171% 31.8364%, 51.6041% 22.8508%, 34.0991% 62.4389%, 32.9579% 36.4932%, 55.0049% 76.3408%, 58.0528% 28.1207%, 91.9813% 25.503%, 14.5145% 71.8511%, 10.9604% 76.7725%, 26.32% 84.4955%, 30.7719% 45.7209%, 79.7214% 49.9124%, 18.4345% 90.539%, 32.5528% 12.6138%, 70.8919% 39.8851%, 9.22003% 70.5041%)
	}
    35% {
        clip-path: polygon(49.7641% 53.0019%, 25.6054% 98.7543%, 27.4108% 78.037%, 77.1073% 66.8787%, 32.2445% 93.3026%, 47.9439% 69.5638%, 55.613% 111.18%, 79.3912% 69.0511%, 85.9554% 121.909%, 48.863% 83.3952%, 21.3071% 61.0361%, 61.1572% 64.3148%, 40.7071% 31.4451%, 26.9038% 3.77891%, 84.1984% 39.8585%, 88.7677% 59.4993%)
	}
    70% {
        clip-path: polygon(58.2628% 84.2732%, 113.639% 89.1192%, 57.2027% 76.9395%, 20.502% 99.4565%, 85.8755% 42.5655%, 91.8627% 61.1447%, 21.6448% 6.54516%, 1.08161% 30.396%, 106.292% 114.16%, 34.3189% 30.1896%, 49.0758% 79.8768%, 95.1084% 65.6199%, 44.031% 21.9923%, 30.8826% 22.6401%, 41.0286% 22.1505%, 10.3577% 53.1075%)
	}
    85% {
        clip-path: polygon(75.3217% 21.3738%, 22.6848% 96.67%, 48.2793% 34.9315%, 87.4925% 80.3883%, 65.1625% 10.7124%, 19.5687% 96.4508%, 51.5691% -11.0032%, -4.00764% 47.9794%, 23.2687% 0.126554%, 54.648% 31.5607%, 45.4702% 104.642%, 77.8014% 56.3428%, 71.324% 62.7337%, 35.7153% 3.56039%, 51.2383% 18.8738%, 83.3939% 32.2304%)
	}
    100% {
        clip-path: polygon(59.786% 53.1476%, 25.1063% 24.1675%, 75.4327% 51.6432%, 48.405% 4.74935%, 61.0629% 1.91479%, 67.1678% 28.9968%, 90.8913% -3.93364%, 37.738% 10.1199%, 83.8292% 14.627%, 22.1647% 78.1524%, 52.5993% 37.2246%, 85.7008% 88.9326%, 85.6679% 38.7789%, 8.79248% 1.92042%, 62.9664% 21.6092%, 46.965% 107.912%)
	}
}

@keyframes bounce_1__1 {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(15px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes bounce_2__1 {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-15px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes eyes_1__1 {
    0% { opacity: 0 }

	18% { opacity: 0 }
	19% { opacity: 1 }
	20% { opacity: 0 }

    42% { opacity: 0 }
	43% { opacity: 1 }
	44% { opacity: 0 }

    70% { opacity: 0 }
	71% { opacity: 1 }
	72% { opacity: 0 }

    90% { opacity: 0 }
	91% { opacity: 1 }
	92% { opacity: 0 }

	100% { opacity: 0 }
}

@keyframes eyes_2__1 {
    0% { opacity: 0 }

    48% { opacity: 0 }
	49% { opacity: 1 }
	50% { opacity: 0 }

    80% { opacity: 0 }
	81% { opacity: 1 }
	82% { opacity: 0 }

	100% { opacity: 0 }
}

@keyframes laptop_1__1 {
    0% { opacity: 1 }

    49% { opacity: 1 }
    50% { opacity: 0 }

    99% { opacity: 0 }
	100% { opacity: 1 }
}

@keyframes laptop_2__1 {
    0% { opacity: 0 }
	100% { opacity: 0 }
}

@keyframes scroll_1__1 {
	100% {
		top: 35px;
		opacity: 0;
	}
}





$colors: (
    "tt": transparent,
    "we": white,
    "bk": black,
    "rd": red,
    "oe": orange,

    "main-1": #454258,
    "main-2": #487977,
    "main-3": #fd4d00,
    "main-4": #f87b3b,
    "main-5": #53779a,
    "main-6": #3cc3a2,
    "main-7": #dbe1e4,
    "main-8": #d9ddde,
    "main-9": #cc2b2e,
    "main-10": #edf2f5,
    "main-11": #dce1e2,
    "main-12": #3ebda1,

    "theme-1": #454258,
    "theme-2": #487977,

    "gradient-1": #53779A,
    "gradient-2": #50839C,
    "gradient-3": #4F839B,
    "gradient-4": #4C909D,
    "gradient-5": #4B919D,
    "gradient-6": #479C9E,
    "gradient-7": #489E9F,
    "gradient-8": #44A99F,
    "gradient-9": #44ABA0,
    "gradient-10": #40B6A1,
    "gradient-11": #3FB7A1,
    "gradient-12": #3CC3A2,

    "gradient-theme-1": linear-gradient(90deg, var(--color-main-5) 0%, var(--color-main-6) 100%),
    "gradient-theme-3": linear-gradient(90deg, var(--color-we) 0%, var(--color-we) 30%, var(--color-main-7) 100%),

    "1": #f2f2f2,
    "2": #cccccc,
    "3": #e2e2e2,
    "4": rgba(0, 0, 0, .5),
    "5": rgba(0, 0, 0, .8),
    "6": #777777,
    "7": #c3c3c3,
    "8": rgba(255, 255, 255, .2),
    "9": rgba(0, 0, 0, .2),
    "10": #222222,
    "11": #999999,
    "12": rgba(#f2f2f2, .8),
    "13": rgba(255, 255, 255, .5),
    "14": #bbbbbb,
    "15": rgba(0, 0, 0, .9),
    "16": #555555,
);

$fonts: (
    "main-rr": "Poppins-Regular",

    "1-mm": "Poppins-Medium",
    "1-sibd": "Poppins-SemiBold",
    "1-bd": "Poppins-Bold",
);

:root {
    @each $name, $color in $colors {
        --color-#{$name}: #{$color};
    }
    @each $name, $font in $fonts {
        --font-#{$name}: #{$font};
    }
}

@each $name, $color in $colors {
    [#{$decoratorsAttribute}~="bd=#{$name}"] {
        background: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="bd-cr=#{$name}"] {
        background-color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="br-cr=#{$name}"] {
        border-color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="co=#{$name}"] {
        color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="se=#{$name}"] {
        stroke: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="fl=#{$name}"] {
        fill: var(--color-#{$name});
    }
}

@each $name, $font in $fonts {
    [#{$decoratorsAttribute}~="ft-fy=#{$name}"] {
        font-family: var(--font-#{$name});
    }
}



[data-d~="fl=ul(1)"] * { fill: url(#gradient-1) }
[data-d~="fl=ul(2)"] * { fill: url(#gradient-2) }

[data-d~="bd=1"] { background: linear-gradient(220deg, var(--color-main-5) 0%, var(--color-main-6) 100%) }

[data-d~="bd=2"] { background: linear-gradient(90deg, var(--color-main-5) 0%, var(--color-main-6) 100%) }

[data-d~="bd=3"] { background: linear-gradient(90deg, var(--color-we) 0%, var(--color-we) 30%, var(--color-main-7) 100%) }

[data-d~="bd=4"] { background: linear-gradient(90deg, var(--color-we) 0%, var(--color-main-8) 100%) }

[data-d~="bd=5"] { background: linear-gradient(90deg, var(--color-main-9) 0%, var(--color-rd) 100%) }

[data-d~="bd=6"] { background: linear-gradient(0deg, var(--color-we) 0%, var(--color-we) 30%, var(--color-main-10) 100%) }



[data-d~="bd-ie=Texture_1__1"] { background-image: url("/resources/textures/Texture_1__1.webp") }
[data-d~="bd-ie=Texture_2__1"] { background-image: url("/resources/textures/Texture_2__1.webp") }
[data-d~="bd-ie=Texture_3__1"] { background-image: url("/resources/textures/Texture_3__1.webp") }

[data-d~="bd-ie=1"] { background-image: url("/resources/Background_1__1.webp") }
[data-d~="bd-ie=2"] { background-image: url("/resources/Background_2__1.webp") }

[data-d~="bd-rt=no-rt"] { background-repeat: no-repeat }

[data-d~="bd-se=cr"] { background-size: cover }

[data-d~="bd-pn=cr"] { background-position: center }

[data-d~="bd-at=fd"] { background-attachment: fixed }

[data-d~="fr=gyse(1)"] { filter: grayscale(1) }
[data-d~="fr=br(1)"] { filter: blur(80px) }
[data-d~="fr=br(100px)"] { filter: blur(100px) }



[data-d~="br=ne"] { border: none }

[data-d~="br-se=sd"] { border-style: solid }
[data-d~="br-se=dd"] { border-style: dashed }
[data-d~="br-tp-se=sd"] { border-top-style: solid }
[data-d~="br-tp-se=dd"] { border-top-style: dashed }
[data-d~="br-rt-se=sd"] { border-right-style: solid }
[data-d~="br-bm-se=sd"] { border-bottom-style: solid }
[data-d~="br-lt-se=sd"] { border-left-style: solid }
[data-d~=":nt(:ft-cd)=br-lt-se=sd"]:not(:first-child) { border-left-style: solid }





[data-d~="br-ce=ce"] { border-collapse: collapse }

[data-d~="br-sg=2px"] { border-spacing: 2px }

[data-d~="br-wh=1px"] { border-width: 1px }
[data-d~="br-wh=2px"] { border-width: 2px }

[data-d~="br-tp-wh=10px"] { border-top-width: 10px }
[data-d~="br-tp-wh=15px"] { border-top-width: 15px }
[data-d~="br-rt-wh=10px"] { border-right-width: 10px }
[data-d~="br-bm-wh=15px"] { border-bottom-width: 15px }
[data-d~="br-bm-wh=10px"] { border-bottom-width: 10px }
[data-d~="br-lt-wh=10px"] { border-left-width: 10px }
[data-d~="br-lt-wh=15px"] { border-left-width: 15px }

[data-d~="br-tp-cr=tt"] { border-top-color: var(--color-tt) }
[data-d~="br-tp-cr=10"] { border-top-color: var(--color-10) }

[data-d~="br-rt-cr=tt"] { border-right-color: var(--color-tt) }
[data-d~="br-rt-cr=we"] { border-right-color: var(--color-we) }

[data-d~="br-bm-cr=tt"] { border-bottom-color: var(--color-tt) }
[data-d~="br-bm-cr=we"] { border-bottom-color: var(--color-we) }
[data-d~="br-bm-cr=main-1"] { border-bottom-color: var(--color-main-1) }
[data-d~="br-bm-cr=10"] { border-bottom-color: var(--color-10) }

[data-d~="br-lt-cr=tt"] { border-left-color: var(--color-tt) }
[data-d~="br-lt-cr=we"] { border-left-color: var(--color-we) }
[data-d~="br-lt-cr=bk"] { border-left-color: var(--color-bk) }
[data-d~="br-lt-cr=main-1"] { border-left-color: var(--color-main-1) }
[data-d~="br-lt-cr=main-2"] { border-left-color: var(--color-main-2) }

[data-d~="br-rs=2px"] { border-radius: 2px }
[data-d~="br-rs=3px"] { border-radius: 3px }
[data-d~="br-rs=5px"] { border-radius: 5px }
[data-d~="br-rs=6px"] { border-radius: 6px }
[data-d~="br-rs=10px"] { border-radius: 10px }
[data-d~="br-rs=20px"] { border-radius: 20px }
[data-d~="br-rs=100%"] { border-radius: 100% }
[data-d~="br-rs=15px"] { border-radius: 15px }
[data-d~="br-tp-lt-rs=3px"] { border-top-left-radius: 3px }
[data-d~="br-bm-lt-rs=3px"] { border-bottom-left-radius: 3px }

[data-d~="br-tp-lt-rs=6px"] { border-top-left-radius: 6px }
[data-d~="br-tp-rt-rs=6px"] { border-top-right-radius: 6px }

[data-d~=":nt(:ft-cd)=br-tp-se=sd"]:not(:first-child) { border-top-style: solid }



[data-d~="bx-sw=1"] { box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .2) }
[data-d~="bx-sw=2"] { box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, .5) }
[data-d~="bx-sw=3"] { box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, .08) }
[data-d~="bx-sw=4"] { box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .1) }



[data-d~="gd-te=1"] { grid-template: min-content min-content 1fr min-content 1fr min-content / 100% }
[data-d~="gd-te=2"] { grid-template: auto 1fr / 100% }
[data-d~="gd-te=3"] { grid-template: 1fr 1fr / 100% }
[data-d~="gd-te=4"] { grid-template: auto 1fr auto / 100% }
[data-d~="gd-te=5"] { grid-template: min-content 1fr min-content / 100% }
[data-d~="gd-te=6"] { grid-template: min-content 1fr / 100% }
[data-d~="gd-te=7"] { grid-template: min-content 1fr min-content min-content / 100% }
[data-d~="gd-te=8"] { grid-template: min-content min-content auto min-content min-content / 100% }
[data-d~="gd-te=9"] { grid-template: min-content min-content auto min-content / 100% }
[data-d~="gd-te=10"] { grid-template: min-content auto / 100% }
[data-d~="gd-te=11"] { grid-template: min-content min-content min-content auto min-content min-content / 100% }
[data-d~="gd-te=12"] { grid-template: min-content auto / 100% }



[data-d~="gd-te-cs=1fr"] { grid-template-columns: 1fr }
[data-d~="gd-te-cs=100%"] { grid-template-columns: 100% }
[data-d~="gd-te-cs=1"] { grid-template-columns: repeat(2, 50%) }

[data-d~="gd-te-rs=1fr"] { grid-template-rows: 1fr }
[data-d~="gd-te-rs=auto1fr"] { grid-template-rows: auto 1fr }
[data-d~="gd-te-rs=1"] { grid-template-rows: 1fr min-content }
[data-d~="gd-te-rs=2"] { grid-template-rows: min-content 1fr }
[data-d~="gd-te-rs=3"] { grid-template-rows: min-content 1fr min-content }



[data-d~="|[100%]"] { grid-template-columns: 100% }

[data-d~="_[mn-ct|ao]"] { grid-template-rows: min-content auto }
[data-d~="_[mn-ct|ao|mn-ct]"] { grid-template-rows: min-content auto min-content }





[data-d~="at-ro=20"] { aspect-ratio: 100 / 20 }
[data-d~="at-ro=30"] { aspect-ratio: 100 / 30 }
[data-d~="at-ro=40"] { aspect-ratio: 100 / 40 }
[data-d~="at-ro=55"] { aspect-ratio: 100 / 55 }
[data-d~="at-ro=60"] { aspect-ratio: 100 / 60 }
[data-d~="at-ro=70"] { aspect-ratio: 100 / 70 }
[data-d~="at-ro=80"] { aspect-ratio: 100 / 80 }
[data-d~="at-ro=100"] { aspect-ratio: 100 / 100 }

[data-d~="at-ro=100/15"] { aspect-ratio: 100 / 15 }
[data-d~="at-ro=100/20"] { aspect-ratio: 100 / 20 }
[data-d~="at-ro=100/24"] { aspect-ratio: 100 / 23.5 }
[data-d~="at-ro=100/30"] { aspect-ratio: 100 / 30 }
[data-d~="at-ro=100/50"] { aspect-ratio: 100 / 50 }
[data-d~="at-ro=100/85"] { aspect-ratio: 100 / 85 }
[data-d~="at-ro=100/100"] { aspect-ratio: 100 / 100 }
[data-d~="at-ro=100/130"] { aspect-ratio: 100 / 130 }
[data-d~="at-ro=100/200"] { aspect-ratio: 100 / 200 }



[data-d~="wh=il"] { width: initial }
[data-d~="wh=0"] { width: 0 }
[data-d~="wh=14%"] { width: 14% }
[data-d~="wh=25%"] { width: 25% }
[data-d~="wh=26%"] { width: 26% }
[data-d~="wh=100%"] { width: 100% }
[data-d~="wh=150%"] { width: 150% }
[data-d~="wh=1px"] { width: 1px }
[data-d~="wh=2px"] { width: 2px }
[data-d~="wh=3px"] { width: 3px }
[data-d~="wh=5px"] { width: 5px }
[data-d~="wh=7px"] { width: 7px }
[data-d~="wh=8px"] { width: 8px }
[data-d~="wh=10px"] { width: 10px }
[data-d~="wh=11px"] { width: 11px }
[data-d~="wh=12px"] { width: 12px }
[data-d~="wh=13px"] { width: 13px }
[data-d~="wh=14px"] { width: 14px }
[data-d~="wh=15px"] { width: 15px }
[data-d~="wh=16px"] { width: 16px }
[data-d~="wh=20px"] { width: 20px }
[data-d~="wh=25px"] { width: 25px }
[data-d~="wh=26px"] { width: 26px }
[data-d~="wh=30px"] { width: 30px }
[data-d~="wh=35px"] { width: 35px }
[data-d~="wh=40px"] { width: 40px }
[data-d~="wh=45px"] { width: 45px }
[data-d~="wh=50px"] { width: 50px }
[data-d~="wh=60px"] { width: 60px }
[data-d~="wh=70px"] { width: 70px }
[data-d~="wh=80px"] { width: 80px }
[data-d~="wh=85px"] { width: 85px }
[data-d~="wh=100px"] { width: 100px }
[data-d~="wh=110px"] { width: 110px }
[data-d~="wh=120px"] { width: 120px }
[data-d~="wh=140px"] { width: 140px }
[data-d~="wh=150px"] { width: 150px }
[data-d~="wh=200px"] { width: 200px }
[data-d~="wh=250px"] { width: 250px }
[data-d~="wh=300px"] { width: 300px }
[data-d~="wh=400px"] { width: 400px }
[data-d~="wh=450px"] { width: 450px }
[data-d~="wh=500px"] { width: 500px }
[data-d~="wh=600px"] { width: 600px }
[data-d~="wh=1500px"] { width: 1500px }
[data-d~="wh=10000px"] { width: 10000px }

[data-d~="mn-wh=20px"] { min-width: 20px }
[data-d~="mn-wh=25px"] { min-width: 25px }
[data-d~="mn-wh=35px"] { min-width: 35px }
[data-d~="mn-wh=40px"] { min-width: 40px }
[data-d~="mn-wh=70px"] { min-width: 70px }
[data-d~="mn-wh=90px"] { min-width: 90px }
[data-d~="mn-wh=100px"] { min-width: 100px }
[data-d~="mn-wh=120px"] { min-width: 120px }
[data-d~="mn-wh=140px"] { min-width: 140px }
[data-d~="mn-wh=155x"] { min-width: 155px }
[data-d~="mn-wh=200px"] { min-width: 200px }
[data-d~="mn-wh=300px"] { min-width: 300px }
[data-d~="mn-wh=400px"] { min-width: 400px }
[data-d~="mn-wh=100%"] { min-width: 100% }

[data-d~="mx-wh=120px"] { max-width: 120px }
[data-d~="mx-wh=150px"] { max-width: 150px }
[data-d~="mx-wh=250px"] { max-width: 250px }
[data-d~="mx-wh=300px"] { max-width: 300px }
[data-d~="mx-wh=90%"] { max-width: 90% }
[data-d~="mx-wh=100%"] { max-width: 100% }



[data-d~="ht=il!"] { height: initial !important }
[data-d~="ht=il"] { height: initial }
[data-d~="ht=ao"] { height: auto }
[data-d~="ht=0"] { height: 0 }
[data-d~="ht=100%"] { height: 100% }
[data-d~="ht=150%"] { height: 150% }
[data-d~="ht=1px"] { height: 1px }
[data-d~="ht=2px"] { height: 2px }
[data-d~="ht=2px!"] { height: 2px !important }
[data-d~="ht=3px"] { height: 3px }
[data-d~="ht=7px"] { height: 7px }
[data-d~="ht=8px"] { height: 8px }
[data-d~="ht=10px"] { height: 10px }
[data-d~="ht=11px"] { height: 11px }
[data-d~="ht=15px"] { height: 15px }
[data-d~="ht=16px"] { height: 16px }
[data-d~="ht=20px"] { height: 20px }
[data-d~="ht=25px"] { height: 25px }
[data-d~="ht=26px"] { height: 26px }
[data-d~="ht=30px"] { height: 30px }
[data-d~="ht=35px"] { height: 35px }
[data-d~="ht=40px"] { height: 40px }
[data-d~="ht=50px"] { height: 50px }
[data-d~="ht=60px"] { height: 60px }
[data-d~="ht=80px"] { height: 80px }
[data-d~="ht=100px"] { height: 100px }
[data-d~="ht=120px"] { height: 120px }
[data-d~="ht=145px"] { height: 145px }
[data-d~="ht=150px"] { height: 150px }
[data-d~="ht=200px"] { height: 200px }
[data-d~="ht=250px"] { height: 250px }
[data-d~="ht=380px"] { height: 380px }
[data-d~="ht=500px"] { height: 500px }
[data-d~="ht=600px"] { height: 600px }
[data-d~="ht=100%"] { height: 100% }
[data-d~="ht=60vh"] { height: 60vh }
[data-d~="ht=100vh"] { height: 100vh }
[data-d~="ht=100dvh"] { height: 100dvh }
[data-d~="ht=150svh"] { height: 150svh }
[data-d~="ht=200svh"] { height: 200svh }
[data-d~="ht=250svh"] { height: 250svh }
[data-d~="ht=300vh"] { height: 300vh }
[data-d~="ht=300svh"] { height: 300svh }

[data-d~="mn-ht=15px"] { min-height: 15px }
[data-d~="mn-ht=20px"] { min-height: 20px }
[data-d~="mn-ht=25px"] { min-height: 25px }
[data-d~="mn-ht=30px"] { min-height: 30px }
[data-d~="mn-ht=35px"] { min-height: 35px }
[data-d~="mn-ht=40px"] { min-height: 40px }
[data-d~="mn-ht=50px"] { min-height: 50px }
[data-d~="mn-ht=60px"] { min-height: 60px }
[data-d~="mn-ht=80px"] { min-height: 80px }
[data-d~="mn-ht=85px"] { min-height: 85px }
[data-d~="mn-ht=90px"] { min-height: 90px }
[data-d~="mn-ht=100px"] { min-height: 100px }

[data-d~="mn-ht=200px"] { min-height: 200px }
[data-d~="mn-ht=300px"] { min-height: 300px }
[data-d~="mn-ht=350px"] { min-height: 350px }
[data-d~="mn-ht=420px"] { min-height: 420px }
[data-d~="mn-ht=100%"] { min-height: 100% }
[data-d~="mn-ht=100vh"] { min-height: 100vh }
[data-d~="mn-ht=100svh"] { min-height: 100svh }
[data-d~="mn-ht=250svh"] { min-height: 250svh }

[data-d~="mx-ht=200px"] { max-height: 200px }
[data-d~="mx-ht=350px"] { max-height: 350px }
[data-d~="mx-ht=500px"] { max-height: 500px }
[data-d~="mx-ht=100%"] { max-height: 100% }





[data-d~="ft-se=1px"] { font-size: 1px }
[data-d~="ft-se=8rem"] { font-size: 8rem }
[data-d~="ft-se=10rem"] { font-size: 10rem }
[data-d~="ft-se=11rem"] { font-size: 11rem }
[data-d~="ft-se=12rem"] { font-size: 12rem }
[data-d~="ft-se=13rem"] { font-size: 13rem }
[data-d~="ft-se=14rem"] { font-size: 14rem }
[data-d~="ft-se=15rem"] { font-size: 15rem }
[data-d~="ft-se=16rem"] { font-size: 16rem }
[data-d~="ft-se=18rem"] { font-size: 18rem }
[data-d~="ft-se=20rem"] { font-size: 20rem }
[data-d~="ft-se=22rem"] { font-size: 22rem }
[data-d~="ft-se=24rem"] { font-size: 24rem }
[data-d~="ft-se=26rem"] { font-size: 26rem }
[data-d~="ft-se=28rem"] { font-size: 28rem }
[data-d~="ft-se=30rem"] { font-size: 30rem }
[data-d~="ft-se=35rem"] { font-size: 35rem }
[data-d~="ft-se=40rem"] { font-size: 40rem }
[data-d~="ft-se=50rem"] { font-size: 50rem }
[data-d~="ft-se=60rem"] { font-size: 60rem }
[data-d~="ft-se=70rem"] { font-size: 70rem }
[data-d~="ft-se=75rem"] { font-size: 75rem }
[data-d~="ft-se=80rem"] { font-size: 80rem }
[data-d~="ft-se=100rem"] { font-size: 100rem }
[data-d~="ft-se=120rem"] { font-size: 120rem }
[data-d~="ft-se=140rem"] { font-size: 140rem }
[data-d~="ft-se=160rem"] { font-size: 160rem }
[data-d~="ft-se=200rem"] { font-size: 200rem }

[data-d~="lr-sg=1rem"] { letter-spacing: 1rem }
[data-d~="lr-sg=2rem"] { letter-spacing: 2rem }
[data-d~="lr-sg=2.4rem"] { letter-spacing: 2.4rem }
[data-d~="lr-sg=3rem"] { letter-spacing: 3rem }
[data-d~="lr-sg=3.5rem"] { letter-spacing: 3.5rem }
[data-d~="lr-sg=6rem"] { letter-spacing: 6rem }
[data-d~="lr-sg=7.4rem"] { letter-spacing: 7.4rem }
[data-d~="lr-sg=9rem"] { letter-spacing: 9rem }
[data-d~="lr-sg=30rem"] { letter-spacing: 30rem }

[data-d~="wd-sg=5rem"] { word-spacing: 5rem }

[data-d~="le-ht=0"] { line-height: 0 }
[data-d~="le-ht=.8"] { line-height: .8 }
[data-d~="le-ht=.9"] { line-height: .9 }
[data-d~="le-ht=1"] { line-height: 1 }
[data-d~="le-ht=1.1"] { line-height: 1.1 }
[data-d~="le-ht=1.2"] { line-height: 1.2 }
[data-d~="le-ht=1.3"] { line-height: 1.3 }
[data-d~="le-ht=1.4"] { line-height: 1.4 }
[data-d~="le-ht=1.6"] { line-height: 1.6 }
[data-d~="le-ht=1.8"] { line-height: 1.8 }

[data-d~="tt-an=lt"] { text-align: left }
[data-d~="tt-an=rt"] { text-align: right }
[data-d~="tt-an=cr"] { text-align: center }

[data-d~="ft-wt=nl"] { font-weight: normal }
[data-d~="ft-wt=bd"] { font-weight: bold }

[data-d~="tt-tm=ue"] { text-transform: uppercase }

[data-d~="tt-dn=ue"] { text-decoration: underline }
[data-d~="tt-dn=le-th"] { text-decoration: line-through }

[data-d~="wd-bk=bk-al"] { word-break: break-all }
[data-d~="wd-bk=kp-al"] { word-break: keep-all }

[data-d~="we-se=nowp"] { white-space: nowrap }

[data-d~="wg-me=vl-rl"] { writing-mode: vertical-rl }

[data-d~="-wt-bd-cp=tt"] { -webkit-background-clip: text }
[data-d~="-wt-tt-fl-cr=tt"] { -webkit-text-fill-color: transparent }





[data-d~="pn=re"] { position: relative }
[data-d~="pn=ae"] { position: absolute }
[data-d~="pn=fd"] { position: fixed }
[data-d~="pn=sy"] { position: sticky }

[data-d~="tp=map"] { top: 65% }
[data-d~="tp=-40px"] { top: -40px }
[data-d~="tp=-20px"] { top: -20px }
[data-d~="tp=-12px"] { top: -12px }
[data-d~="tp=-10px"] { top: -10px }
[data-d~="tp=0"] { top: 0 }
[data-d~="tp=5px"] { top: 5px }
[data-d~="tp=6px"] { top: 6px }
[data-d~="tp=20px"] { top: 20px }
[data-d~="tp=25px"] { top: 25px }
[data-d~="tp=35px"] { top: 35px }
[data-d~="tp=40px"] { top: 40px }
[data-d~="tp=100px"] { top: 100px }
[data-d~="tp=120px"] { top: 120px }
[data-d~="tp=140px"] { top: 140px }
[data-d~="tp=180px"] { top: 180px }
[data-d~="tp=-5%"] { top: -5% }
[data-d~="tp=5%"] { top: 5% }
[data-d~="tp=6%"] { top: 6% }
[data-d~="tp=18.6%"] { top: 18.6% }
[data-d~="tp=20%"] { top: 20% }
[data-d~="tp=100%"] { top: 100% }

[data-d~="rt=-200px"] { right: -200px }
[data-d~="rt=-100px"] { right: -100px }
[data-d~="rt=-40px"] { right: -40px }
[data-d~="rt=-20px"] { right: -20px }
[data-d~="rt=-15px"] { right: -15px }
[data-d~="rt=-12px"] { right: -12px }
[data-d~="rt=-10px"] { right: -10px }
[data-d~="rt=-5px"] { right: -5px }
[data-d~="rt=-4px"] { right: -4px }
[data-d~="rt=-3px"] { right: -3px }
[data-d~="rt=0"] { right: 0 }
[data-d~="rt=5px"] { right: 5px }
[data-d~="rt=10px"] { right: 10px }
[data-d~="rt=15px"] { right: 15px }
[data-d~="rt=20px"] { right: 20px }
[data-d~="rt=30px"] { right: 30px }
[data-d~="rt=40px"] { right: 40px }
[data-d~="rt=13.5%"] { right: 13.5% }
[data-d~="rt=14%"] { right: 14% }
[data-d~="rt=15%"] { right: 15% }

[data-d~="bm=-20px"] { bottom: -20px }
[data-d~="bm=-12px"] { bottom: -12px }
[data-d~="bm=-10px"] { bottom: -10px }
[data-d~="bm=0"] { bottom: 0 }
[data-d~="bm=10px"] { bottom: 10px }
[data-d~="bm=12px"] { bottom: 12px }
[data-d~="bm=15px"] { bottom: 15px }
[data-d~="bm=20px"] { bottom: 20px }
[data-d~="bm=40px"] { bottom: 40px }
[data-d~="bm=50px"] { bottom: 50px }
[data-d~="bm=60px"] { bottom: 60px }
[data-d~="bm=80px"] { bottom: 80px }
[data-d~="bm=20%"] { bottom: 20% }
[data-d~="bm=25%"] { bottom: 25% }
[data-d~="bm=29%"] { bottom: 29% }

[data-d~="lt=map"] { left: 55% }
[data-d~="lt=-200px"] { left: -200px }
[data-d~="lt=-20px"] { left: -20px }
[data-d~="lt=-14px"] { left: -14px }
[data-d~="lt=-12px"] { left: -12px }
[data-d~="lt=0"] { left: 0 }
[data-d~="lt=10px"] { left: 10px }
[data-d~="lt=20px"] { left: 20px }
[data-d~="lt=30px"] { left: 30px }
[data-d~="lt=40px"] { left: 40px }
[data-d~="lt=-15px"] { left: -15px }
[data-d~="lt=-10px"] { left: -10px }
[data-d~="lt=-5%"] { left: -5% }
[data-d~="lt=5%"] { left: 5% }
[data-d~="lt=13.5%"] { left: 13.5% }
[data-d~="lt=14%"] { left: 14% }
[data-d~="lt=15%"] { left: 15% }
[data-d~="lt=17%"] { left: 17% }
[data-d~="lt=20%"] { left: 20% }



[data-d~="lt-se-te=il"] { list-style-type: initial }
[data-d~="lt-se-te=ne"] { list-style-type: none }
[data-d~="lt-se-te=ne!"] { list-style-type: none !important }
[data-d~="lt-se-te=dc"] { list-style-type: disc }

[data-d~="ow=hn"] { overflow: hidden }
[data-d~="ow=ao"] { overflow: auto }
[data-d~="ow=cp"] { overflow: clip }

[data-d~="ow-x=ao"] { overflow-x: auto }
[data-d~="ow-y=ao"] { overflow-y: auto }
[data-d~="ow-y=sl"] { overflow-y: scroll }

[data-d~="oe=ne"] { outline: none }

[data-d~="cu=pr"] { cursor: pointer }

[data-d~="ot-ft=cr"] { object-fit: cover }

[data-d~="ot-pn=tp"] { object-position: top }
[data-d~="ot-pn=rt"] { object-position: right }
[data-d~="ot-pn=bm"] { object-position: bottom }
[data-d~="ot-pn=lt"] { object-position: left }
[data-d~="ot-pn=cr"] { object-position: center }

[data-d~="dy=ne"] { display: none }

[data-d~="ft=lt"] { float: left }

[data-d~="oy=0"] { opacity: 0 }
[data-d~="oy=.3"] { opacity: .3 }

[data-d~="vy=hn"] { visibility: hidden }

[data-d~="pr-es=ne"] { pointer-events: none }
[data-d~="pr-es=al"] { pointer-events: all }

[data-d~="vl-an=tp"] { vertical-align: top }
[data-d~="vl-an=me"] { vertical-align: middle }

[data-d~="cr=bh"] { clear: both }

[data-d~="ie-rg=-wt-oe-ct"] { image-rendering: -webkit-optimize-contrast }

[data-d~="or=1"] { order: 1 }
[data-d~="or=2"] { order: 2 }
[data-d~="or=3"] { order: 3 }

[data-d~="clip-path=st(1)"] { clip-path: inset(35% round 30px) }

[data-d~="z-ix=100"] { z-index: 100 }
[data-d~="z-ix=200"] { z-index: 200 }
[data-d~="z-ix=300"] { z-index: 300 }
[data-d~="z-ix=400"] { z-index: 400 }
[data-d~="z-ix=500"] { z-index: 500 }
[data-d~="z-ix=1000"] { z-index: 1000 }
[data-d~="z-ix=2000"] { z-index: 2000 }
[data-d~="z-ix=10000"] { z-index: 10000 }
[data-d~="z-ix=20000"] { z-index: 20000 }
[data-d~="z-ix=30000"] { z-index: 30000 }
[data-d~="z-ix=40000"] { z-index: 40000 }
[data-d~="z-ix=50000"] { z-index: 50000 }
[data-d~="z-ix=60000"] { z-index: 60000 }



[data-d~="pg=il!"] { padding: initial !important }
[data-d~="pg=0"] { padding: 0 }
[data-d~="pg=0!"] { padding: 0 !important }
[data-d~="pg=1px"] { padding: 1px }
[data-d~="pg=2px"] { padding: 2px }
[data-d~="pg=3px"] { padding: 3px }
[data-d~="pg=4px"] { padding: 4px }
[data-d~="pg=5px"] { padding: 5px }
[data-d~="pg=7px"] { padding: 7px }
[data-d~="pg=10px"] { padding: 10px }
[data-d~="pg=15px"] { padding: 15px }
[data-d~="pg=20px"] { padding: 20px }
[data-d~="pg=30px"] { padding: 30px }
[data-d~="pg=40px"] { padding: 40px }
[data-d~="pg=5px10px"] { padding: 5px 10px }
[data-d~="pg=40px0"] { padding: 40px 0 }
[data-d~="pg=10px5px"] { padding: 10px 5px }
[data-d~="pg=5px20px"] { padding: 5px 20px }
[data-d~="pg=10px20px"] { padding: 10px 20px }
[data-d~="pg=15px20px"] { padding: 15px 20px }

[data-d~="pg-tp=il"] { padding-top: initial }
[data-d~="pg-tp=0"] { padding-top: 0 }
[data-d~="pg-tp=2px"] { padding-top: 2px }
[data-d~="pg-tp=4px"] { padding-top: 4px }
[data-d~="pg-tp=5px"] { padding-top: 5px }
[data-d~="pg-tp=10px"] { padding-top: 10px }
[data-d~="pg-tp=15px"] { padding-top: 15px }
[data-d~="pg-tp=20px"] { padding-top: 20px }
[data-d~="pg-tp=30px"] { padding-top: 30px }
[data-d~="pg-tp=40px"] { padding-top: 40px }
[data-d~="pg-tp=50px"] { padding-top: 50px }
[data-d~="pg-tp=60px"] { padding-top: 60px }
[data-d~="pg-tp=70px"] { padding-top: 70px }
[data-d~="pg-tp=80px"] { padding-top: 80px }
[data-d~="pg-tp=90px"] { padding-top: 90px }
[data-d~="pg-tp=100px"] { padding-top: 100px }
[data-d~="pg-tp=110px"] { padding-top: 110px }
[data-d~="pg-tp=120px"] { padding-top: 120px }
[data-d~="pg-tp=140px"] { padding-top: 140px }
[data-d~="pg-tp=150px"] { padding-top: 150px }
[data-d~="pg-tp=160px"] { padding-top: 160px }
[data-d~="pg-tp=200px"] { padding-top: 200px }
[data-d~="pg-tp=300px"] { padding-top: 300px }
[data-d~="pg-tp=5%"] { padding-top: 5% }
[data-d~="pg-tp=10%"] { padding-top: 10% }
[data-d~="pg-tp=15%"] { padding-top: 15% }
[data-d~="pg-tp=15.5%"] { padding-top: 15.5% }
[data-d~="pg-tp=20%"] { padding-top: 20% }
[data-d~="pg-tp=22%"] { padding-top: 22% }
[data-d~="pg-tp=25%"] { padding-top: 25% }
[data-d~="pg-tp=30%"] { padding-top: 30% }
[data-d~="pg-tp=31%"] { padding-top: 31% }
[data-d~="pg-tp=35%"] { padding-top: 35% }
[data-d~="pg-tp=43%"] { padding-top: 43% }
[data-d~="pg-tp=80%"] { padding-top: 80% }
[data-d~=":nt(:ft-cd)=pg-tp=30px"]:not(:first-child) { padding-top: 30px }

[data-d~="pg-rt=5px"] { padding-right: 5px }
[data-d~="pg-rt=10px"] { padding-right: 10px }
[data-d~="pg-rt=15px"] { padding-right: 15px }
[data-d~="pg-rt=20px"] { padding-right: 20px }
[data-d~="pg-rt=25px"] { padding-right: 25px }
[data-d~="pg-rt=30px"] { padding-right: 30px }
[data-d~="pg-rt=35px"] { padding-right: 35px }
[data-d~="pg-rt=40px"] { padding-right: 40px }
[data-d~="pg-rt=60px"] { padding-right: 60px }
[data-d~="pg-rt=100px"] { padding-right: 100px }
[data-d~="pg-rt=10%"] { padding-right: 20% }

[data-d~="pg-bm=il"] { padding-bottom: initial }
[data-d~="pg-bm=0"] { padding-bottom: 0 }
[data-d~="pg-bm=2px"] { padding-bottom: 2px }
[data-d~="pg-bm=3px"] { padding-bottom: 3px }
[data-d~="pg-bm=5px"] { padding-bottom: 5px }
[data-d~="pg-bm=10px"] { padding-bottom: 10px }
[data-d~="pg-bm=20px"] { padding-bottom: 20px }
[data-d~="pg-bm=30px"] { padding-bottom: 30px }
[data-d~="pg-bm=40px"] { padding-bottom: 40px }
[data-d~="pg-bm=50px"] { padding-bottom: 50px }
[data-d~="pg-bm=60px"] { padding-bottom: 60px }
[data-d~="pg-bm=60px"] { padding-bottom: 60px }
[data-d~="pg-bm=80px"] { padding-bottom: 80px }
[data-d~="pg-bm=100px"] { padding-bottom: 100px }
[data-d~="pg-bm=140px"] { padding-bottom: 140px }
[data-d~="pg-bm=5%"] { padding-bottom: 5% }

[data-d~="pg-lt=0"] { padding-left: 0 }
[data-d~="pg-lt=1px"] { padding-left: 1px }
[data-d~="pg-lt=2px"] { padding-left: 2px }
[data-d~="pg-lt=5px"] { padding-left: 5px }
[data-d~="pg-lt=10px"] { padding-left: 10px }
[data-d~="pg-lt=15px"] { padding-left: 15px }
[data-d~="pg-lt=20px"] { padding-left: 20px }
[data-d~="pg-lt=25px"] { padding-left: 25px }
[data-d~="pg-lt=30px"] { padding-left: 30px }
[data-d~="pg-lt=40px"] { padding-left: 40px }
[data-d~=":nt(:ft-cd)=pg-lt=20px"]:not(:first-child) { padding-left: 20px }

[data-d~="mn=-10px"] { margin: -10px }

[data-d~="mn-tp=-20px"] { margin-top: -20px }
[data-d~="mn-tp=-1px"] { margin-top: -1px }
[data-d~="mn-tp=0"] { margin-top: 0 }
[data-d~="mn-tp=1px"] { margin-top: 1px }
[data-d~="mn-tp=2px"] { margin-top: 2px }
[data-d~="mn-tp=3px"] { margin-top: 3px }
[data-d~="mn-tp=5px"] { margin-top: 5px }
[data-d~="mn-tp=10px"] { margin-top: 10px }
[data-d~="mn-tp=20px"] { margin-top: 20px }
[data-d~="mn-tp=30px"] { margin-top: 30px }
[data-d~="mn-tp=40px"] { margin-top: 40px }
[data-d~="mn-tp=60px"] { margin-top: 60px }
[data-d~="mn-tp=100px"] { margin-top: 100px }
[data-d~="mn-tp=140px"] { margin-top: 140px }
[data-d~="mn-tp=200px"] { margin-top: 200px }
[data-d~=":nt(:ft-cd)=mn-tp=5px"]:not(:first-child) { margin-top: 5px }
[data-d~=":nt(:ft-cd)=mn-tp=10px"]:not(:first-child) { margin-top: 10px }
[data-d~=":nt(:ft-cd)=mn-tp=20px"]:not(:first-child) { margin-top: 20px }

[data-d~="mn-rt=-20px"] { margin-right: -20px }
[data-d~="mn-rt=10px"] { margin-right: 10px }
[data-d~="mn-rt=20px"] { margin-right: 20px }
[data-d~="mn-rt=40px"] { margin-right: 40px }

[data-d~="mn-bm=0"] { margin-bottom: 0 }
[data-d~="mn-bm=-20px"] { margin-bottom: -20px }
[data-d~="mn-bm=-60px"] { margin-bottom: -60px }
[data-d~="mn-bm=5px"] { margin-bottom: 5px }
[data-d~="mn-bm=10px"] { margin-bottom: 10px }
[data-d~="mn-bm=20px"] { margin-bottom: 20px }
[data-d~="mn-bm=50px"] { margin-bottom: 50px }

[data-d~="mn-lt=-20px"] { margin-left: -20px }
[data-d~="mn-lt=-3px"] { margin-left: -3px }
[data-d~="mn-lt=0"] { margin-left: 0 }
[data-d~="mn-lt=2px"] { margin-left: 2px }
[data-d~="mn-lt=5px"] { margin-left: 5px }
[data-d~="mn-lt=10px"] { margin-left: 10px }
[data-d~="mn-lt=15px"] { margin-left: 15px }
[data-d~="mn-lt=20px"] { margin-left: 20px }
[data-d~="mn-lt=34px"] { margin-left: 34px }
[data-d~="mn-lt=40px"] { margin-left: 40px }
[data-d~="mn-lt=80px"] { margin-left: 80px }



[data-d~="tn=1"] { transition: all .8s cubic-bezier(.44, .15, .2, 1) }

[data-d~="tn-dn=250ms"] { transition-duration: 250ms }
[data-d~="tn-dn=500ms"] { transition-duration: 500ms }

[data-d~="tn-dy=50ms"] { transition-delay: 50ms }
[data-d~="tn-dy=100ms"] { transition-delay: 100ms }
[data-d~="tn-dy=150ms"] { transition-delay: 150ms }
[data-d~="tn-dy=200ms"] { transition-delay: 200ms }
[data-d~="tn-dy=250ms"] { transition-delay: 250ms }

[data-d~="tm=teX(-100vw)"] { transform: translateX(-100vw) }
[data-d~="tm=teX(-100%)"] { transform: translateX(-100%) }
[data-d~="tm=teX(100%)"] { transform: translateX(100%) }
[data-d~="tm=teX(100vw)"] { transform: translateX(100vw) }

[data-d~="tm=teY(-30px)"] { transform: translateY(-30px) }
[data-d~="tm=teY(40px)"] { transform: translateY(40px) }

[data-d~="re=-45deg"] { rotate: -45deg }
[data-d~="re=-5deg"] { rotate: -5deg }
[data-d~="re=5deg"] { rotate: 5deg }
[data-d~="re=45deg"] { rotate: 45deg }
[data-d~="re=90deg"] { rotate: 90deg }
[data-d~="re=180deg"] { rotate: 180deg }

[data-d~="tm=re(90deg)"] { transform: rotate(90deg) }

[data-d~="te-lt=fd"] { table-layout: fixed }

[data-d~="an-in-ct=ie"] { animation-iteration-count: infinite }

[data-d~="an-dn=1s"] { animation-duration: 1s }
[data-d~="an-dn=1.5s"] { animation-duration: 1.5s }
[data-d~="an-dn=2s"] { animation-duration: 2s }
[data-d~="an-dn=5s"] { animation-duration: 5s }
[data-d~="an-dn=10s"] { animation-duration: 10s }
[data-d~="an-dn=15s"] { animation-duration: 15s }
[data-d~="an-dn=20s"] { animation-duration: 20s }
[data-d~="an-dn=50s"] { animation-duration: 50s }

[data-d~="an-dy=1s"] { animation-delay: 1s }

[data-d~="an-ne=1"] { animation-name: pulse_1__1 }
[data-d~="an-ne=2"] { animation-name: marquee_1__1 }
[data-d~="an-ne=3"] { animation-name: triangles_1__1 }
[data-d~="an-ne=4"] { animation-name: bounce_1__1 }
[data-d~="an-ne=5"] { animation-name: bounce_2__1 }
[data-d~="an-ne=6"] { animation-name: levitate_1__1 }
[data-d~="an-ne=7"] { animation-name: eyes_1__1 }
[data-d~="an-ne=8"] { animation-name: eyes_2__1 }
[data-d~="an-ne=9"] { animation-name: scroll_1__1 }
[data-d~="an-ne=10"] { animation-name: rotate_1__1 }
[data-d~="an-ne=11"] { animation-name: levitate_2__1 }
[data-d~="an-ne=12"] { animation-name: levitate_3__1 }

[data-d~="an-tg-fn=ss(1, ed)"] { animation-timing-function: steps(1, end) }
[data-d~="an-tg-fn=lr"] { animation-timing-function: linear }
[data-d~="an-tg-fn=ee-in-ot"] { animation-timing-function: ease-in-out }
[data-d~="an-py-se=pd"] { animation-play-state: paused }
[data-d~="an-fl-me=ne"] { animation-fill-mode: forwards }



[data-d~="fx-sk=0"] { flex-shrink: 0 }

[data-d~="sc=0"] { scale: 0 }



@keyframes reset {
    0% {
        transform: translateY(0)
    }
    100% {
        transform: translateY(0)
    }
}







[data-d~=":hr->wh"] {
    &:hover {
        [data-d~=":hr->wh=100%"] { width: 100% }
        [data-d~=":hr->wh=30px"] { width: 30px }
    }
}

[data-d~=":fs-wn->wh"] {
    &:focus-within {
        [data-d~=":fs-wn->wh=100%"] { width: 100% }
        [data-d~=":fs-wn->wh=30px"] { width: 30px }
    }
}


[data-d~=":hr->co"] {
    &:hover {
        [data-d~=":hr->co=we"] { color: var(--color-we) }
        [data-d~=":hr->co=bk"] { color: var(--color-bk) }
        [data-d~=":hr->co=main-2"] { color: var(--color-main-2) }
    }
}

[data-d~=":fs-wn->co"] {
    &:focus-within {
        [data-d~=":fs-wn->co=we"] { color: var(--color-we) }
        [data-d~=":fs-wn->co=bk"] { color: var(--color-bk) }
        [data-d~=":fs-wn->co=main-2"] { color: var(--color-main-2) }
    }
}


[data-d~=":hr->co.1"] {
    &:hover {
        [data-d~=":hr->co.1=we"] { color: var(--color-we) }
        [data-d~=":hr->co.1=bk"] { color: var(--color-bk) }
    }
}

[data-d~=":fs-wn->co.1"] {
    &:focus-within {
        [data-d~=":fs-wn->co.1=we"] { color: var(--color-we) }
        [data-d~=":fs-wn->co.1=bk"] { color: var(--color-bk) }
    }
}



[data-d~=":hr->bd-cr"] {
    &:hover {
        [data-d~=":hr->bd-cr=bk"] { background-color: var(--color-bk) }
        [data-d~=":hr->bd-cr=theme-1"] { background-color: var(--color-theme-1) }
        [data-d~=":hr->bd-cr=theme-2"] { background-color: var(--color-theme-2) }
    }
}

[data-d~=":fs-wn->bd-cr"] {
    &:focus-within {
        [data-d~=":fs-wn->bd-cr=bk"] { background-color: var(--color-bk) }
        [data-d~=":fs-wn->bd-cr=theme-1"] { background-color: var(--color-theme-1) }
        [data-d~=":fs-wn->bd-cr=theme-2"] { background-color: var(--color-theme-2) }
    }
}



[data-d~=":hr->br-cr"] {
    &:hover {
        &[data-d~=":hr->br-cr=bk"], [data-d~=":hr->br-cr=bk"] { border-color: var(--color-bk) }
        &[data-d~=":hr->br-cr=main-1"], [data-d~=":hr->br-cr=main-1"] { border-color: var(--color-main-1) }
        &[data-d~=":hr->br-cr=theme-2"], [data-d~=":hr->br-cr=theme-2"] { border-color: var(--color-theme-2) }
    }
}

[data-d~=":fs-wn->br-cr"] {
    &:focus-within {
        &[data-d~=":fs-wn->br-cr=bk"], [data-d~=":fs-wn->br-cr=bk"] { border-color: var(--color-bk) }
        &[data-d~=":fs-wn->br-cr=main-1"], [data-d~=":fs-wn->br-cr=main-1"] { border-color: var(--color-main-1) }
        &[data-d~=":fs-wn->br-cr=theme-2"], [data-d~=":fs-wn->br-cr=theme-2"] { border-color: var(--color-theme-2) }
    }
}



[data-d~=":hr->bx-sw"] {
    &:hover {
        &[data-d~=":hr->bx-sw=1"],
        [data-d~=":hr->bx-sw=1"] { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2) }
    }
}

[data-d~=":fs-wn->bx-sw"] {
    &:focus-within {
        &[data-d~=":fs-wn->bx-sw=1"],
        [data-d~=":fs-wn->bx-sw=1"] { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2) }
    }
}


[data-d~=":hr->tt-dn"] {
    &:hover {
        [data-d~=":hr->tt-dn=ue"] { text-decoration: underline }
    }
}

[data-d~=":fs-wn->tt-dn"] {
    &:focus-within {
        [data-d~=":fs-wn->tt-dn=ue"] { text-decoration: underline }
    }
}


[data-d~=":hr->se"] {
    &:hover {
        [data-d~=":hr->se=1.1"] { scale: 1.1 }
        [data-d~=":hr->se=1.2"] { scale: 1.2 }
    }
}

[data-d~=":fs-wn->se"] {
    &:focus-within {
        [data-d~=":fs-wn->se=1.1"] { scale: 1.1 }
        [data-d~=":fs-wn->se=1.2"] { scale: 1.2 }
    }
}



[data-d~=":hr->re"] {
    &:hover {
        [data-d~=":hr->re=5deg"] { rotate: 5deg }
    }
}

[data-d~=":fs-wn->re"] {
    &:focus-within {
        [data-d~=":fs-wn->re=5deg"] { rotate: 5deg }
    }
}



[data-d~=":hr->oy"] {
    &:hover {
        [data-d~=":hr->oy=1"] { opacity: 1 }
    }
}

[data-d~=":fs-wn->oy"] {
    &:focus-within {
        [data-d~=":fs-wn->oy=1"] { opacity: 1 }
    }
}


[data-d~=":hr->tm=teY"] {
	&:hover {
		[data-d~=":hr->tm=teY(0)"] { transform: translateY(0) }
	}
}

[data-d~=":fs-wn->tm=teY"] {
	&:focus-within {
		[data-d~=":fs-wn->tm=teY(0)"] { transform: translateY(0) }
	}
}


[data-d~=":hr->pr-es"] {
	&:hover {
		[data-d~=":hr->pr-es=ao"] { pointer-events: auto }
	}
}

[data-d~=":fs-wn->pr-es"] {
	&:focus-within {
		[data-d~=":fs-wn->pr-es=ao"] { pointer-events: auto }
	}
}


[data-d~=":hr->an-py-se"] {
	&:hover {
		[data-d~=":hr->an-py-se=rg"] { animation-play-state: running }
	}
}

[data-d~=":fs-wn->pr-es"] {
	&:focus-within {
		[data-d~=":fs-wn->an-py-se=rg"] { animation-play-state: running }
	}
}


[data-d~=":hr->an-py-se"] {
	&:hover {
		[data-d~=":hr->an-py-se=pd"] { animation-play-state: running }
	}
}

[data-d~=":fs-wn->pr-es"] {
	&:focus-within {
		[data-d~=":fs-wn->an-py-se=pd"] { animation-play-state: running }
	}
}


[data-d~=":hr->dy"] {
    &:hover {
        [data-d~=":hr->dy=fx"] { display: flex }
    }
}

[data-d~=":fs-wn->dy"] {
    &:focus-within {
        [data-d~=":fs-wn->dy=fx"] { display: flex }
    }
}





[data-d~=":hr=tt-dn=ue"]:hover { text-decoration: underline }

[data-d~="::pr=co=we"]::placeholder { color: var(--color-we) }
[data-d~="::pr=co=bk"]::placeholder { color: var(--color-bk) }

[data-d~="::-wt-sr=oy=0"]::-webkit-scrollbar { opacity: 0 }
[data-d~="::-wt-sr=dy=ne"]::-webkit-scrollbar { display: none }

[data-d~=":hr=an-py-se=pd"]:hover { animation-play-state: paused }
[data-d~=":fs-wn=an-py-se=pd"]:focus-within { animation-play-state: paused }

[data-d~=":fs-wn=tm=teX(0)"]:focus-within { transform: translateX(0) }













$columns: 36;
$breakpoints: (
    "2000": 2000px,
    "1600": 1600px,
    "1450": 1450px,
    "1300": 1300px,
    "1000": 1000px,
    "600": 600px,
);

$gridsEnabled: true;
$gridsAttribute: "data-g";
$gridsBreakpoints: true;
$grids: (
    "0": ( width: 0 ),
    "4.5": ( width: 12.5% ),
    "27.8%": ( width: 27.8% ),
    "it": ( width: inherit ),
    "il": ( width: initial ),
    "ut": ( width: unset ),
);

$aligmentsEnabled: true;
$aligmentsAtrribute: "data-a";
$aligmentsBreakpoints: true;
$aligments: (
    "": ( display: flex ),
    "fx": ( display: flex ),
    "gd": ( display: grid ),
    "ie": ( display: inline ),
    "ie-bk": ( display: inline-block ),
    "ie-fx": ( display: inline-flex ),
    "bk": ( display: block ),
    "ne": ( display: none ),
    "it": ( display: inherit ),
    "il": ( display: initial ),
    "ut": ( display: unset ),

    "#": ( flex-wrap: wrap ),
    "#=nowp": ( flex-wrap: nowrap ),
    "#=wp-re": ( flex-wrap: wrap-reverse ),
    "#=it": ( flex-wrap: inherit ),
    "#=il": ( flex-wrap: initial ),
    "#=rt": ( flex-wrap: revert ),
    "#=rt-lr": ( flex-wrap: revert-layer ),
    "#=ut": ( flex-wrap: unset ),

    "x.": ( justify-content: flex-start ),
    ".x.": ( justify-content: center ),
    ".x": ( justify-content: flex-end ),
    "x.x": ( justify-content: space-between ),
    ".x.x.": ( justify-content: space-evenly ),
    ".x..x.": ( justify-content: space-around ),
    "x": ( justify-content: stretch ),
    "x=it": ( justify-content: inherit ),
    "x=il": ( justify-content: initial ),
    "x=ut": ( justify-content: unset ),
    "x=nl": ( justify-content: normal ),
    "&x.": ( justify-self: flex-start ),
    "&.x": ( justify-self: flex-end ),
    "&x=il": ( justify-self: initial ),

    "y.": ( align-items: flex-start ),
    ".y.": ( align-items: center ),
    ".y": ( align-items: flex-end ),
    "y": ( align-items: stretch ),
    "y=it": ( align-items: inherit ),
    "y=il": ( align-items: initial ),
    "y=ut": ( align-items: unset ),
    "y=nl": ( align-items: normal ),
    "&y.": ( align-self: flex-start ),
    "&.y.": ( align-self: center ),
    "&.y": ( align-self: flex-end ),
);

$decoratorsEnabled: false;
$decoratorsAttribute: "data-d";
$decoratorsBreakpoints: false;
$decorators: ();

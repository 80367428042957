@use "sass:math";

@media print {
    @page {
        margin: .75cm .75cm .75cm .75cm;
        size: A4;
    }





    [data-a~="@print=ne"] { display: none }
    [data-a~="@print=fx"] { display: flex }
    [data-a~="@print=gd"] { display: grid }
    [data-a~="@print=#"] { flex-wrap: wrap }





    [data-g~="@print=20%"] { width: 20% }

    [data-g~="@print=18"] { width: math.div(100, math.div(36, 18)) * 1% }
    [data-g~="@print=36"] { width: math.div(100, math.div(36, 36)) * 1% }

    [data-g~="@print=>12"] > * { width: math.div(100, math.div(36, 12)) * 1% }





    [data-d~="@print=gd-te=1"] { grid-template: min-content auto min-content / 100% }





    [data-d~="@print=bd=il"] { background: initial }

    [data-d~="@print=bd-cr=il"] { background-color: initial }


    [data-d~="@print=br-se=dd"] { border-style: dashed }

    [data-d~="@print=br-cr=main-2"] { border-color: var(--color-main-2) }
    [data-d~="@print=br-cr=main-4"] { border-color: var(--color-main-4) }
    [data-d~="@print=br-cr=14"] { border-color: var(--color-14) }

    [data-d~="@print=br-wh=1px"] { border-width: 1px }



    [data-d~="@print=wh=14px"] { width: 14px }
    [data-d~="@print=wh=30px"] { width: 30px }

    [data-d~="@print=mn-wh=il"] { min-width: initial }



    [data-d~="@print=ht=14px"] { height: 14px }

    [data-d~="@print=mn-ht=il"] { min-height: initial }
    [data-d~="@print=mn-ht=14px"] { min-height: 14px }
    [data-d~="@print=mn-ht=20px"] { min-height: 20px }
    [data-d~="@print=mn-ht=100vh"] { min-height: 100vh }


    [data-d~="@print=tp=-7px"] { top: -7px }


    [data-d~="@print=ft-se=8rem"] { font-size: 8rem }
    [data-d~="@print=ft-se=10rem"] { font-size: 10rem }
    [data-d~="@print=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@print=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@print=ft-se=18rem"] { font-size: 18rem }


    [data-d~="@print=co=bk"] { color: var(--color-bk) }


    [data-d~="@print=le-ht=1"] { line-height: 1 }


    [data-d~="@print=pe-bk-be=as"] { page-break-before: always }
    [data-d~="@print=pe-bk-ie=ad"] { page-break-inside: avoid }


    [data-d~="@print=pg=5px"] { padding: 5px }

    [data-d~="@print=pg-tp=il"] { padding-top: initial }
    [data-d~="@print=pg-tp=5px"] { padding-top: 5px }
    [data-d~="@print=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@print=pg-tp=15px"] { padding-top: 15px }
    [data-d~="@print=pg-tp=20px"] { padding-top: 20px }

    [data-d~="@print=pg-rt=10px"] { padding-right: 10px }

    [data-d~="@print=pg-bm=il"] { padding-bottom: initial }

    [data-d~="@print=pg-lt=10px"] { padding-left: 10px }

}

@use "sass:math";

@if $gridsEnabled {
    @each $grid, $declaration in $grids {
        @each $property, $value in $declaration {
            [#{$gridsAttribute}~="#{$grid}"] {
                #{$property}: $value;
            }
            [#{$gridsAttribute}~=">#{$grid}"] > * {
                #{$property}: $value;
            }
        }
    }
    @for $grid from 1 through $columns {
        [#{$gridsAttribute}~="#{$grid}"] {
            width: math.div(100, math.div($columns, $grid)) * 1%
        }
        [#{$gridsAttribute}~=">#{$grid}"] > * {
            width: math.div(100, math.div($columns, $grid)) * 1%
        }
    }
    @if $gridsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media screen and (max-width: $resolution) {
                @each $grid, $declaration in $grids {
                    @each $property, $value in $declaration {
                        [#{$gridsAttribute}~="@#{$breakpoint}=#{$grid}"] {
                            #{$property}: $value;
                        }
                        [#{$gridsAttribute}~="@#{$breakpoint}=>#{$grid}"] > * {
                            #{$property}: $value;
                        }
                    }
                }
                @for $grid from 1 through $columns {
                    [#{$gridsAttribute}~="@#{$breakpoint}=#{$grid}"] {
                        width: math.div(100, math.div($columns, $grid)) * 1%
                    }
                    [#{$gridsAttribute}~="@#{$breakpoint}=>#{$grid}"] > * {
                        width: math.div(100, math.div($columns, $grid)) * 1%
                    }
                }
            }
        }
    }
}

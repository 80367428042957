@if $aligmentsEnabled {
    @each $aligment, $declaration in $aligments {
        @each $property, $value in $declaration {
            @if $aligment != "" {
                [#{$aligmentsAtrribute}~="#{$aligment}"] {
                    #{$property}: $value;
                }
            } @else {
                [#{$aligmentsAtrribute}] {
                    #{$property}: $value;
                }
            }
        }
    }
    @if $aligmentsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media screen and (max-width: $resolution) {
                @each $aligment, $declaration in $aligments {
                    @each $property, $value in $declaration {
                        @if $aligment != "" {
                            [#{$aligmentsAtrribute}~="@#{$breakpoint}=#{$aligment}"] {
                                #{$property}: $value;
                            }
                        } @else {
                            [#{$aligmentsAtrribute}~="@#{$breakpoint}"] {
                                #{$property}: $value;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 2000px) {
    [data-d~="@2000=br-rs=3px"] { border-radius: 3px }


    [data-d~="@2000=wh=30px"] { width: 30px }
    [data-d~="@2000=wh=40px"] { width: 40px }
    [data-d~="@2000=wh=60px"] { width: 60px }
    [data-d~="@2000=wh=80px"] { width: 80px }
    [data-d~="@2000=wh=100px"] { width: 100px }
    [data-d~="@2000=wh=200px"] { width: 200px }
    [data-d~="@2000=wh=400px"] { width: 400px }


    [data-d~="@2000=mn-wh=100px"] { min-width: 100px }


    [data-d~="@2000=ht=30px"] { height: 30px }
    [data-d~="@2000=ht=70vh"] { height: 70vh }

    [data-d~="@2000=mn-ht=30px"] { min-height: 30px }
    [data-d~="@2000=mn-ht=40px"] { min-height: 40px }


    [data-d~="@2000=bm=10px"] { bottom: 10px }

    [data-d~="@2000=lt=10px"] { left: 10px }


    [data-d~="@2000=ft-se=11rem"] { font-size: 11rem }
    [data-d~="@2000=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@2000=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@2000=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@2000=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@2000=ft-se=20rem"] { font-size: 20rem }
    [data-d~="@2000=ft-se=24rem"] { font-size: 24rem }
    [data-d~="@2000=ft-se=30rem"] { font-size: 30rem }
    [data-d~="@2000=ft-se=40rem"] { font-size: 40rem }
    [data-d~="@2000=ft-se=50rem"] { font-size: 50rem }
    [data-d~="@2000=ft-se=60rem"] { font-size: 60rem }
    [data-d~="@2000=ft-se=80rem"] { font-size: 80rem }
    [data-d~="@2000=ft-se=130rem"] { font-size: 130rem }
    [data-d~="@2000=ft-se=160rem"] { font-size: 160rem }


    [data-d~="@2000=lr-sg=il"] { letter-spacing: initial }
    [data-d~="@2000=lr-sg=1.3rem"] { letter-spacing: 1.3rem }
    [data-d~="@2000=lr-sg=2rem"] { letter-spacing: 2rem }
    [data-d~="@2000=lr-sg=3rem"] { letter-spacing: 3rem }
    [data-d~="@2000=lr-sg=4.2rem"] { letter-spacing: 4.2rem }


    [data-d~="@2000=pg=5px"] { padding: 5px }
    [data-d~="@2000=pg=7px"] { padding: 7px }

    [data-d~="@2000=pg-tp=50px"] { padding-top: 50px }
    [data-d~="@2000=pg-tp=70px"] { padding-top: 70px }
    [data-d~="@2000=pg-tp=80px"] { padding-top: 80px }

    [data-d~="@2000=pg-rt=10px"] { padding-right: 10px }

    [data-d~="@2000=pg-bm=20px"] { padding-bottom: 20px }
    [data-d~="@2000=pg-bm=60px"] { padding-bottom: 60px }

    [data-d~="@2000=pg-lt=10px"] { padding-left: 10px }

}



@media screen and (max-width: 1600px) {
    [data-d~="@1600=wh=30px"] { width: 30px }
    [data-d~="@1600=wh=40px"] { width: 40px }
    [data-d~="@1600=wh=50px"] { width: 50px }
    [data-d~="@1600=wh=60px"] { width: 60px }
    [data-d~="@1600=wh=80px"] { width: 80px }


    [data-d~="@1600=mn-ht=70px"] { min-height: 70px }


    [data-d~="@1600=ft-se=13rem"] { font-size: 13rem }
    [data-d~="@1600=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@1600=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@1600=ft-se=20rem"] { font-size: 20rem }
    [data-d~="@1600=ft-se=24rem"] { font-size: 24rem }
    [data-d~="@1600=ft-se=25rem"] { font-size: 25rem }
    [data-d~="@1600=ft-se=30rem"] { font-size: 30rem }
    [data-d~="@1600=ft-se=35rem"] { font-size: 35rem }
    [data-d~="@1600=ft-se=40rem"] { font-size: 40rem }
    [data-d~="@1600=ft-se=50rem"] { font-size: 50rem }
    [data-d~="@1600=ft-se=60rem"] { font-size: 60rem }
    [data-d~="@1600=ft-se=100rem"] { font-size: 100rem }
    [data-d~="@1600=ft-se=120rem"] { font-size: 120rem }


    [data-d~="@1600=lr-sg=.2rem"] { letter-spacing: .2rem }
    [data-d~="@1600=lr-sg=.5rem"] { letter-spacing: .5rem }
    [data-d~="@1600=lr-sg=2.6rem"] { letter-spacing: 2.6rem }


    [data-d~="@1600=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@1600=pg-tp=40px"] { padding-top: 40px }
    [data-d~="@1600=pg-tp=50px"] { padding-top: 50px }

    [data-d~="@1600=pg-rt=10px"] { padding-right: 10px }

    [data-d~="@1600=pg-bm=60px"] { padding-bottom: 60px }

    [data-d~="@1600=pg-lt=10px"] { padding-left: 10px }

}



@media screen and (max-width: 1450px) {
    [data-d~="@1450=wh=30px"] { width: 30px }


    [data-d~="@1450=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@1450=ft-se=13rem"] { font-size: 13rem }
    [data-d~="@1450=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@1450=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@1450=ft-se=30rem"] { font-size: 30rem }
    [data-d~="@1450=ft-se=40rem"] { font-size: 40rem }
    [data-d~="@1450=ft-se=70rem"] { font-size: 70rem }


    [data-d~="@1450=pg-tp=10px"] { padding-top: 10px }

    [data-d~="@1450=pg-rt=10px"] { padding-right: 10px }

    [data-d~="@1450=pg-lt=10px"] { padding-left: 10px }
    [data-d~="@1450=pg-lt=20px"] { padding-left: 20px }

}



@media screen and (max-width: 1300px) {
    [data-d~="@1300=ht=80px"] { height: 80px }

}



@media screen and (max-width: 1000px) {
    [data-d~="@1000=clip-path=il"] { clip-path: initial }


    [data-d~="@1000=bd=il"] { background: initial }


    [data-d~="@1000=bd-cr=il"] { background-color: initial }
    [data-d~="@1000=bd-cr=we"] { background-color: var(--color-we) }
    [data-d~="@1000=bd-cr=bk"] { background-color: var(--color-bk) }


    [data-d~="@1000=gd-te=1"] { grid-template: min-content 1fr / 100% }


    [data-d~="@1000=br=ne"] { border: none }
    [data-d~="@1000=br=ne!"] { border: none !important }

    [data-d~="@1000=br-se=ne"] { border-style: none }
    [data-d~="@1000=br-se=sd"] { border-style: solid }

    [data-d~="@1000=br-wh=1px"] { border-width: 1px }

    [data-d~="@1000=br-rs=il"] { border-radius: initial }
    [data-d~="@1000=br-rs=4px"] { border-radius: 4px }
    [data-d~="@1000=br-rs=30px"] { border-radius: 30px }
    [data-d~="@1000=br-rs=100%"] { border-radius: 100% }

    [data-d~="@1000=br-cr=3"] { border-color: var(--color-3) }
    [data-d~="@1000=br-cr=8"] { border-color: var(--color-8) }


    [data-d~="@1000=at-ro=100/40"] { aspect-ratio: 100 / 40 }
    [data-d~="@1000=at-ro=100/100"] { aspect-ratio: 100 / 100 }
    [data-d~="@1000=at-ro=100/180"] { aspect-ratio: 100 / 180 }


    [data-d~="@1000=wh=20px"] { width: 20px }
    [data-d~="@1000=wh=25px"] { width: 25px }
    [data-d~="@1000=wh=30px"] { width: 30px }
    [data-d~="@1000=wh=35px"] { width: 35px }
    [data-d~="@1000=wh=40px"] { width: 40px }
    [data-d~="@1000=wh=50px"] { width: 50px }
    [data-d~="@1000=wh=60px"] { width: 60px }
    [data-d~="@1000=wh=100px"] { width: 100px }
    [data-d~="@1000=wh=120px"] { width: 120px }
    [data-d~="@1000=wh=150px"] { width: 150px }
    [data-d~="@1000=wh=100%"] { width: 100% }


    [data-d~="@1000=mn-wh=25px"] { min-width: 25px }
    [data-d~="@1000=mn-wh=200%"] { min-width: 200% }


    [data-d~="@1000=ht=il"] { height: initial }
    [data-d~="@1000=ht=20px"] { height: 20px }
    [data-d~="@1000=ht=30px"] { height: 30px }
    [data-d~="@1000=ht=60px"] { height: 60px }
    [data-d~="@1000=ht=80px"] { height: 80px }
    [data-d~="@1000=ht=100svh"] { height: 100svh }


    [data-d~="@1000=mn-ht=il"] { min-height: initial }
    [data-d~="@1000=mn-ht=35px"] { min-height: 35px }
    [data-d~="@1000=mn-ht=70px"] { min-height: 70px }
    [data-d~="@1000=mn-ht=100px"] { min-height: 100px }


    [data-d~="@1000=pn=il"] { position: initial }
    [data-d~="@1000=pn=fd"] { position: fixed }
    [data-d~="@1000=pn=ae"] { position: absolute }

    [data-d~="@1000=tp=-30%"] { top: -30% }
    [data-d~="@1000=tp=-15px"] { top: -15px }
    [data-d~="@1000=tp=0"] { top: 0 }
    [data-d~="@1000=tp=10px"] { top: 10px }

    [data-d~="@1000=rt=-10px"] { right: -10px }
    [data-d~="@1000=rt=0"] { right: 0 }
    [data-d~="@1000=rt=10px"] { right: 10px }

    [data-d~="@1000=bm=-10px"] { bottom: -10px }
    [data-d~="@1000=bm=0"] { bottom: 0 }
    [data-d~="@1000=bm=100px"] { bottom: 100px }

    [data-d~="@1000=lt=-10px"] { left: -10px }
    [data-d~="@1000=lt=0"] { left: 0 }
    [data-d~="@1000=lt=20px"] { left: 20px }


    [data-d~="@1000=or=1"] { order: 1 }
    [data-d~="@1000=or=2"] { order: 2 }
    [data-d~="@1000=or=3"] { order: 3 }
    [data-d~="@1000=or=4"] { order: 4 }
    [data-d~="@1000=or=5"] { order: 5 }
    [data-d~="@1000=or=6"] { order: 6 }

    [data-d~="@1000=oy=.6"] { opacity: .6 }

    [data-d~="@1000=ow=cp"] { overflow: clip }



    [data-d~="@1000=ft-se=11rem"] { font-size: 11rem }
    [data-d~="@1000=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@1000=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@1000=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@1000=ft-se=20rem"] { font-size: 20rem }
    [data-d~="@1000=ft-se=24rem"] { font-size: 24rem }
    [data-d~="@1000=ft-se=30rem"] { font-size: 30rem }
    [data-d~="@1000=ft-se=35rem"] { font-size: 35rem }
    [data-d~="@1000=ft-se=40rem"] { font-size: 40rem }
    [data-d~="@1000=ft-se=60rem"] { font-size: 60rem }
    [data-d~="@1000=ft-se=80rem"] { font-size: 80rem }


    [data-d~="@1000=lr-sg=il"] { letter-spacing: initial }
    [data-d~="@1000=lr-sg=1rem"] { letter-spacing: 1rem }
    [data-d~="@1000=lr-sg=3rem"] { letter-spacing: 3rem }
    [data-d~="@1000=lr-sg=5rem"] { letter-spacing: 5rem }
    [data-d~="@1000=lr-sg=10rem"] { letter-spacing: 10rem }


    [data-d~="@1000=le-ht=1"] { line-height: 1 }
    [data-d~="@1000=le-ht=1.4"] { line-height: 1.4 }


    [data-d~="@1000=tt-an=lt!"] { text-align: left !important }
    [data-d~="@1000=tt-an=rt"] { text-align: right }
    [data-d~="@1000=tt-an=cr"] { text-align: center }


    [data-d~="@1000=pg=il"] { padding: initial }
    [data-d~="@1000=pg=2px"] { padding: 2px }
    [data-d~="@1000=pg=5px"] { padding: 5px }
    [data-d~="@1000=pg=10px"] { padding: 10px }
    [data-d~="@1000=pg=15px"] { padding: 15px }
    [data-d~="@1000=pg=20px"] { padding: 20px }

    [data-d~="@1000=pg-tp=il"] { padding-top: initial }
    [data-d~="@1000=pg-tp=5px"] { padding-top: 5px }
    [data-d~="@1000=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@1000=pg-tp=15px"] { padding-top: 15px }
    [data-d~="@1000=pg-tp=20px"] { padding-top: 20px }
    [data-d~="@1000=pg-tp=25px"] { padding-top: 25px }
    [data-d~="@1000=pg-tp=30px"] { padding-top: 30px }
    [data-d~="@1000=pg-tp=40px"] { padding-top: 40px }
    [data-d~="@1000=pg-tp=50px"] { padding-top: 50px }
    [data-d~="@1000=pg-tp=60px"] { padding-top: 60px }
    [data-d~="@1000=pg-tp=80px"] { padding-top: 80px }
    [data-d~="@1000=pg-tp=90px"] { padding-top: 90px }
    [data-d~="@1000=pg-tp=20%"] { padding-top: 20% }
    [data-d~="@1000=pg-tp=31%"] { padding-top: 31% }
    [data-d~="@1000=pg-tp=60%"] { padding-top: 60% }
    [data-d~="@1000=pg-tp=80%"] { padding-top: 80% }
    [data-d~="@1000=pg-tp=100%"] { padding-top: 100% }
    [data-d~=":nt(:ft-cd):nt(:nh-cd(2))=pg-tp=30px"]:not(:first-child):not(:nth-child(2)) { padding-top: 30px }
    [data-d~="@1000=pg-tp=cc(1)"] { padding-top: calc(25% - 10px) }
    [data-d~="@1000=:nh-cd(2)=pg-tp=20px"]:nth-child(2) { padding-top: 20px }
    [data-d~="@1000=:nt(:ft-cd):nt(:nh-cd(2))=pg-tp=50px"]:not(:first-child):not(:nth-child(2)) { padding-top: 50px }

    [data-d~="@1000=pg-rt=il"] { padding-right: initial }
    [data-d~="@1000=pg-rt=5px"] { padding-right: 5px }
    [data-d~="@1000=pg-rt=10px"] { padding-right: 10px }
    [data-d~="@1000=pg-rt=20px"] { padding-right: 20px }

    [data-d~="@1000=pg-bm=il"] { padding-bottom: initial }
    [data-d~="@1000=pg-bm=20px"] { padding-bottom: 20px }
    [data-d~="@1000=pg-bm=60px"] { padding-bottom: 60px }

    [data-d~="@1000=pg-lt=il"] { padding-left: initial }
    [data-d~="@1000=pg-lt=5px"] { padding-left: 5px }
    [data-d~="@1000=pg-lt=10px"] { padding-left: 10px }
    [data-d~="@1000=pg-lt=20px"] { padding-left: 20px }
    [data-d~="@1000=pg-lt=40px"] { padding-left: 40px }
    [data-d~="@1000=:nt(:ft-cd)=pg-lt=10px"]:not(:first-child) { padding-left: 10px }


    [data-d~="@1000=mn-tp=il!"] { margin-top: initial !important }

    [data-d~="@1000=mn-tp=-40px"] { margin-top: -40px }

}



@media screen and (max-width: 600px) {

}

@font-face {
    font-family: "Poppins-Regular";
    src: url("/resources/global/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("/resources/global/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("/resources/global/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("/resources/global/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-display: block;
}
